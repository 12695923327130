body {
    background-color: var(--black);
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


:root {
    /* Colors */
    --white: #FFFFFF;
    --light-grey: #EFEFEF;
    --grey: #DDDDDD;
    --dark-grey: #1F1F1F;
    --black: #0F0C0C;
    --red: #ED1C24;
    --gold: #F0AD4E;

    /* Navbar */
    --navbar-height: 80px;

    /* Icons */
    --icon-size-small: 10px;
    --icon-size-standard: 20px;
    --icon-size-large: 40px;
    --icon-size-super-large: 60px;

    /* Margins */
    --margin-very-small: 5px;
    --margin-small: 10px;
    --margin-standard: 20px;
    --margin-large: 50px;
    --margin-very-large: 100px;
    --margin-side: 64px;

    /* Paddings */
    --padding-small: 10px;
    --padding-standard: 20px;
    --padding-large: 50px;
    --padding-super-large: 100px;

    /* Font Sizes */
    --font-size-very-small: 12px;
    --font-size-small: 16px;
    --font-size-standard: 24px;
    --font-size-large: 40px;
    --font-size-super-large: 60px;
}

/* Mobile */
@media (max-width: 900px) {
    :root {
        /* Navbar */
        --navbar-height: 80px;

        /* Padding */
        --padding-standard: 10px;
        --padding-large: 25px;
        --padding-super-large: 50px;

        /* Font Sizes */
        --font-size-very-small: 10px;
        --font-size-small: 12px;
        --font-size-standard: 14px;
        --font-size-large: 20px;
        --font-size-super-large: 30px;

        /* Margins */
        --margin-side: 32px;
        --margin-very-large: 75px;

        /* Icons */
        --icon-size-super-large: 30px;
    }
}
